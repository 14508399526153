import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Edit,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  Button,
  // SelectInput,
  /* FormDataConsumer,
  ImageField,
  ImageInput, */
  required,
} from 'react-admin';
// import { USER_ROLES } from '../../constants';

// import { IMAGE_MAX_SIZE, IMAGE_MIME_TYPES, STATIC_URL } from '../../constants';

const EditOrganizationToolbar = (props) => {
  const history = useHistory();
  return (
    <Toolbar {...props}>
      <SaveButton />
      <Button
        label="Back"
        style={{ marginLeft: 'auto' }}
        onClick={() => history.goBack()}
      />
    </Toolbar>
  );
};
/* edit fields- missing validation of the entered values */
export default function EditOrganization(props) {
  return (
    <Edit title="Edit organization" {...props}>
      <SimpleForm toolbar={<EditOrganizationToolbar />}>
        <TextInput source="name" validate={[required()]} />
        <TextInput source="billingAddress" validate={[required()]} />
        <TextInput source="city" validate={[required()]} />
        <TextInput source="state" validate={[required()]} />
        <TextInput source="zip" validate={[required()]} />
      </SimpleForm>
    </Edit>
  );
}
