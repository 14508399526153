import React from 'react';

import axios from 'axios';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
  useRecordContext,
  useNotify,
  Filter,
  SelectInput,
  ReferenceField,
  ReferenceInput,
} from 'react-admin';
import { Button } from '@material-ui/core';

import { API_URL, AUTH_TOKEN_NAME } from '../../constants';

const ResetPasswordButton = () => {
  const record = useRecordContext();
  const notify = useNotify();

  const resetPassword = async () => {
    try {
      const token = localStorage.getItem(AUTH_TOKEN_NAME);
      await axios.post(
        `${API_URL}/reset-password`,
        { email: record.email },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify('Reset password link has been sent to user', { type: 'info' });
    } catch (error) {
      console.log(error.response.data);
      notify(error?.response?.data?.message, { type: 'error' });
    }
  };

  if (!record) return null;

  return (
    <div>
      <Button color="primary" onClick={resetPassword}>
        Reset Password
      </Button>
    </div>
  );
};

const UserFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source="orgRoleId"
      reference="org-roles"
      label="Organization role"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="organizationId"
      reference="organizations"
      label="Organization"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export default function UsersList(props) {
  return (
    <List
      {...props}
      sort={{ field: 'createdAt', order: 'DESC' }}
      exporter={false}
      filters={<UserFilter />}
    >
      <Datagrid>
        <TextField source="email" />
        <ReferenceField source="organizationId" reference="organizations">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="orgRoleId"
          reference="org-roles"
          linkType={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" />
        <EditButton basePath="/users" />
        <DeleteButton />
        <ResetPasswordButton />
      </Datagrid>
    </List>
  );
}
