import React from 'react';

// import axios from 'axios';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
  // Filter,
  // SelectInput,
} from 'react-admin';

export default function OrganizationsList(props) {
  return (
    <List
      {...props}
      sort={{ field: 'createdAt', order: 'DESC' }}
      exporter={false}
    >
      <Datagrid>
        <TextField source="name" label="Organization Name" />
        <TextField source="billingAddress" />
        <TextField source="city" />
        <TextField source="state" />
        <TextField source="zip" />
        <DateField source="createdAt" />
        <EditButton basePath="/organizations" />
        <DeleteButton />
      </Datagrid>
    </List>
  );
}
