import React from 'react';
import { Admin, Resource } from 'react-admin';
// import { BusinessIcon } from '@material-ui/icons/Business';
// import { PeopleIcon } from '@material-ui/icons/People';
import restWithFileUploadProvider from '../dataProviders/restWithFileUploadProvider';
import authProvider from '../dataProviders/authProvider';

import UsersList from './Users/UserList';
import CreateUser from './Users/CreateUser';
import EditUsers from './Users/EditUser';
import OrganizationsList from './Organizations/OrganizationList';
import CreateOrganization from './Organizations/CreateOrganization';
import EditOrganization from './Organizations/EditOrganization';

export default () => (
  <Admin dataProvider={restWithFileUploadProvider} authProvider={authProvider}>
    <Resource
      name="organizations"
      create={CreateOrganization}
      list={OrganizationsList}
      edit={EditOrganization}
      // icon={BusinessIcon}
    />
    <Resource
      name="users"
      list={UsersList}
      create={CreateUser}
      edit={EditUsers}
      // icon={<PeopleIcon />}
    />
    <Resource name="org-roles" />
  </Admin>
);
