import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Edit,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  Button,
  SelectInput,
  ReferenceInput,
  /* FormDataConsumer,
  ImageField,
  ImageInput, */
  required,
} from 'react-admin';
// import { USER_ROLES } from '../../constants';

// import { IMAGE_MAX_SIZE, IMAGE_MIME_TYPES, STATIC_URL } from '../../constants';

const EditUserToolbar = (props) => {
  const history = useHistory();
  return (
    <Toolbar {...props}>
      <SaveButton />
      <Button
        label="Back"
        style={{ marginLeft: 'auto' }}
        onClick={() => history.goBack()}
      />
    </Toolbar>
  );
};

export default function EditUser(props) {
  return (
    <Edit title="Edit user" {...props}>
      <SimpleForm toolbar={<EditUserToolbar />}>
        <TextInput source="name" validate={[required()]} />
        {/* <TextInput source="email" validate={[required()]} /> */}

        <ReferenceInput
          label="Organization"
          source="organizationId"
          reference="organizations"
          validate={[]}
        >
          <SelectInput />
        </ReferenceInput>
        <ReferenceInput
          label="Organization Role"
          source="orgRoleId"
          reference="org-roles"
          validate={[]}
        >
          <SelectInput />
        </ReferenceInput>
        {/* <TextInput source="companyName" validate={[required()]} /> */}
        <TextInput source="title" validate={[required()]} />
        {/*  <SelectInput
          source="role"
          choices={[{ id: USER_ROLES.SUPER_ADMIN, name: 'Super admin' }]}
          allowEmpty
        /> */}
        {/* <ImageInput
          source="photoPath"
          accept={IMAGE_MIME_TYPES}
          maxSize={IMAGE_MAX_SIZE}
          label="Avatar"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <FormDataConsumer>
          {({ formData }) => {
            console.log(`${STATIC_URL}${formData.photoPath}`);
            return (
              formData.avatarImagePath && (
                <img src={`${STATIC_URL}${formData.photoPath}`} alt="Header" />
              )
            );
          }}
        </FormDataConsumer> */}
      </SimpleForm>
    </Edit>
  );
}
