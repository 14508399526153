export const API_URL = process.env.REACT_APP_API_URL;
export const STATIC_URL = process.env.REACT_APP_STATIC_URL;
export const AUTH_TOKEN_NAME = 'authToken';
export const IMAGE_MAX_SIZE = 10 * 1024 * 1024;
export const IMAGE_MIME_TYPES = 'image/png, image/jpeg';
export const USER_ROLES = {
  ADMIN: 'admin',
  SUPER_ADMIN: 'super_admin',
  USER: 'user',
};
