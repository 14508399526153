import React from 'react';
import {
  Create,
  // SelectInput,
  SimpleForm,
  TextInput,
  required,
  useRedirect,
} from 'react-admin';

export default function CreateOrganization(props) {
  const maxLength =
    (max, message = 'Too long') =>
    (value) =>
      value && value.length > max ? message : undefined;

  const minLength =
    (max, message = 'Too short') =>
    (value) =>
      value && value.length < max ? message : undefined;

  const zip =
    (min, max, message = 'Must be a numeric zip') =>
    (value) => {
      const numericValue = Number(value.replace('-', ''));
      const isNumeric = !Number.isNaN(numericValue);
      const isWithinRange = value.length >= min && value.length <= max;

      return isNumeric && isWithinRange ? undefined : message;
    };

  const validateCompanyName = [required(), maxLength(50)];

  const validateZipCode = [
    required(),
    minLength(5, 'Too short for a valid ZIP code'),
    maxLength(10, 'Too long for a valid ZIP code'),
    zip(5, 10, 'Must be a number with 5 to 10 digits'),
  ];

  const redirect = useRedirect();
  return (
    <Create
      title="Create a organization"
      {...props}
      onSuccess={() => redirect('/organizations')}
    >
      <SimpleForm>
        <TextInput source="name" validate={[required(), validateCompanyName]} />
        <TextInput source="billingAddress" validate={[required()]} />
        <TextInput source="city" validate={[required()]} />
        <TextInput source="state" validate={[required()]} />
        <TextInput source="zip" validate={[required(), validateZipCode]} />
      </SimpleForm>
    </Create>
  );
}
